import {
    faEnvelope,
    faHome,
    faPhoneFlip,
    faStar,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Row, Col } from "react-bootstrap";
import "./Footer.scss";
import Award1 from "../../assets/images/award-logo-1.png";
import Award2 from "../../assets/images/award-logo-2.png";
import Award3 from "../../assets/images/award-logo-3.png";
import Award4 from "../../assets/images/award-logo-4.png";
function PageFooter() {
    return (
        <footer className="footer">
            <Container className="footer-wrap">
                <div className="footer-contact">
                    <a href="/" className="footer-home">
                        <FontAwesomeIcon icon={faHome}></FontAwesomeIcon> Home
                    </a>
                    <p className="footer-phone">
                        <FontAwesomeIcon icon={faPhoneFlip}></FontAwesomeIcon>{" "}
                        +1 847 346 1801
                    </p>
                    <a
                        href="https://secure.webhostingpad.com/billing/submitticket.php"
                        className="footer-mail"
                    >
                        <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon>{" "}
                        Email
                    </a>
                </div>
                <div className="footer-divide"></div>
                <Row className="footer-body">
                    {/* item1 */}
                    <Col
                        lg={3}
                        md={3}
                        sm={5}
                        xs={6}
                        className="footer-body-item"
                    >
                        <h4 className="footer-body-item-title">Products</h4>
                        <a
                            href="https://www.webhostingpad.com/hosting/"
                            className="footer-body-item-desc"
                        >
                            Website Hosting
                        </a>
                        <a
                            href="https://www.webhostingpad.com/website-builder/"
                            className="footer-body-item-desc"
                        >
                            Website Builder
                        </a>
                        <a
                            href="https://www.webhostingpad.com/wordpress/"
                            className="footer-body-item-desc"
                        >
                            WordPress Hosting
                        </a>
                        <a
                            href="https://www.webhostingpad.com/vps/"
                            className="footer-body-item-desc"
                        >
                            VPS Hosting
                        </a>
                        <a
                            href="https://www.webhostingpad.com/domains/"
                            className="footer-body-item-desc"
                        >
                            Domain Names
                        </a>
                        <a
                            href="https://www.webhostingpad.com/addons/"
                            className="footer-body-item-desc"
                        >
                            Addon Products
                        </a>
                    </Col>
                    {/* item2 */}

                    <Col
                        lg={3}
                        md={3}
                        sm={5}
                        xs={6}
                        className="footer-body-item"
                    >
                        <h4 className="footer-body-item-title">
                            Support & Help
                        </h4>
                        <a
                            href="https://secure.webhostingpad.com/billing/index.php?rp=/login"
                            className="footer-body-item-desc"
                        >
                            Client Area
                        </a>
                        <a
                            href="https://www.webhostingpad.com/help/"
                            className="footer-body-item-desc"
                        >
                            Help Center
                        </a>
                        <a
                            href="https://www.webhostingpad.com/knowledgebase/"
                            className="footer-body-item-desc"
                        >
                            Knowledgebase
                        </a>
                        <a
                            href="https://www.webhostingpad.com/price/"
                            className="footer-body-item-desc"
                        >
                            Pricing Information
                        </a>
                        <a
                            href="https://www.webhostingpad.com/priority-support/"
                            className="footer-body-item-desc"
                        >
                            Priority Support
                        </a>
                        <a
                            href="https://secure.webhostingpad.com/billing/submitticket.php"
                            className="footer-body-item-desc"
                        >
                            Submit a Ticket
                        </a>
                    </Col>
                    {/* item3 */}

                    <Col
                        lg={3}
                        md={3}
                        sm={5}
                        xs={6}
                        className="footer-body-item"
                    >
                        <h4 className="footer-body-item-title">
                            Programs & Pages
                        </h4>
                        <a
                            href="https://www.webhostingpad.com/affiliates/"
                            className="footer-body-item-desc"
                        >
                            Affiliate Program
                        </a>
                        <a
                            href="https://www.webhostingpad.com/refer-a-friend/"
                            className="footer-body-item-desc"
                        >
                            Refer A Friend Program
                        </a>
                        <a
                            href="https://www.webhostingpad.com/awards/"
                            className="footer-body-item-desc"
                        >
                            Awards and Reviews
                        </a>
                        <a
                            href="https://www.webhostingpad.com/sitelock/"
                            className="footer-body-item-desc"
                        >
                            SiteLock
                        </a>
                        <a
                            href="https://www.webhostingpad.com/ssl/"
                            className="footer-body-item-desc"
                        >
                            SSL Certificates
                        </a>
                        <a
                            href="https://www.webhostingpad.com/seo-tools/"
                            className="footer-body-item-desc"
                        >
                            SEO Tools
                        </a>
                    </Col>
                    {/* item4 */}

                    <Col
                        lg={3}
                        md={3}
                        sm={5}
                        xs={6}
                        className="footer-body-item"
                    >
                        <h4 className="footer-body-item-title">Company</h4>
                        <a
                            href="https://www.webhostingpad.com/about/"
                            className="footer-body-item-desc"
                        >
                            About Us
                        </a>
                        <a
                            href="https://www.webhostingpad.com/contact/"
                            className="footer-body-item-desc"
                        >
                            Contact Us
                        </a>
                        <a
                            href="https://www.webhostingpad.com/forms/feedback/"
                            className="footer-body-item-desc"
                        >
                            Leave Feedback
                        </a>
                        <a
                            href="https://www.webhostingpad.com/privacy/"
                            className="footer-body-item-desc"
                        >
                            Privacy Policy
                        </a>
                        <a
                            href="https://www.webhostingpad.com/terms/billing/"
                            className="footer-body-item-desc"
                        >
                            Billing Policy
                        </a>
                        <a
                            href="https://www.webhostingpad.com/terms/"
                            className="footer-body-item-desc"
                        >
                            Terms of Service
                        </a>
                    </Col>
                </Row>
                <div className="footer-awards-title">
                    <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>

                    <a href="https://www.webhostingpad.com/awards/">
                        Check out our Awards and Reviews
                    </a>

                    <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
                </div>
                <Row className="footer-awards">
                    <Col className="footer-award">
                        <img src={Award1} alt="award" className="award-logo" />
                    </Col>
                    <Col className="footer-award">
                        <img src={Award2} alt="award" className="award-logo" />
                    </Col>
                    <Col className="footer-award">
                        <img src={Award3} alt="award" className="award-logo" />
                    </Col>
                    <Col className="footer-award">
                        <img src={Award4} alt="award" className="award-logo" />
                    </Col>
                </Row>
                <div className="footer-divide"></div>
                <Row className="footer-copyright">
                    <Col
                        lg={5}
                        md={5}
                        sm={12}
                        xs={12}
                        className="footer-copyright-item"
                    >
                        © 2023 WebHostingPad.com. All rights reserved.
                    </Col>
                    <Col
                        lg={5}
                        md={5}
                        sm={12}
                        xs={12}
                        className="footer-copyright-item"
                    >
                        * Please see terms of service for complete details.
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}

export default PageFooter;
