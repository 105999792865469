import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../../assets/images/logo-lg.png";
import { faUser } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
function Header() {
    return (
        <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
            <Container>
                <Navbar.Brand href="#">
                    <img className={"logo"} src={logo} alt="WebHostingPad" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link href="#">Plans</Nav.Link>
                        <Nav.Link href="https://www.webhostingpad.com/domains/">
                            Domains
                        </Nav.Link>
                        <Nav.Link href="https://www.webhostingpad.com/contact/">
                            Contact
                        </Nav.Link>
                    </Nav>
                    <Nav>
                        <NavDropdown
                            className="nav-link"
                            title="Language"
                            id="collasible-nav-dropdown"
                        >
                            <NavDropdown.Item href="https://www.webhostingpad.com/">
                                English(USA)
                            </NavDropdown.Item>

                            <NavDropdown.Item href="https://www.webhostingpad.in/">
                                English (India)
                            </NavDropdown.Item>

                            <NavDropdown.Item href="https://cn.webhostingpad.com/">
                                汉语
                            </NavDropdown.Item>

                            <NavDropdown.Item href="https://es.webhostingpad.com/">
                                Español
                            </NavDropdown.Item>

                            <NavDropdown.Item href="https://pt.webhostingpad.com/">
                                Português
                            </NavDropdown.Item>

                            <NavDropdown.Item href="https://vn.webhostingpad.com/kr-new/">
                                한국어
                            </NavDropdown.Item>
                        </NavDropdown>

                        <Nav.Link href="https://secure.webhostingpad.com/billing/index.php?rp=/login">
                            <button className=" login-btn">
                                <FontAwesomeIcon
                                    icon={faUser}
                                    className="user-icon"
                                />
                                <span>Log in</span>
                            </button>
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Header;
