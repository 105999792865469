import PageFooter from "./Component/Footer";
import Header from "./Component/Header";
import Include from "./Component/Include";
import Intro from "./Component/Intro";
import MainContent from "./Component/MainContent";

function App() {
    return (
        <div className="App">
            <header className="header">
                <Header></Header>
            </header>
            <main>
                <Intro></Intro>
                <MainContent></MainContent>
                <Include></Include>
            </main>
            <footer>
                <PageFooter></PageFooter>
            </footer>
        </div>
    );
}

export default App;
