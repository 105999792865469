import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpeditedssl } from "@fortawesome/free-brands-svg-icons";
import "./Include.scss";
import {
    faEnvelope,
    faHardDrive,
    faUser,
} from "@fortawesome/free-regular-svg-icons";
import { faDatabase, faDesktop } from "@fortawesome/free-solid-svg-icons";
import featureRate from "../../assets/images/rate.png";
// import featureFooter from "../../assets/images/room.jpg";
import MoreInformation from "../MoreInfor";
function Include() {
    return (
        <div className="feature-wrap">
            <div className="feature-header">
                <p className="feature-subtitle">Included with your</p>
                <h2 className="feature-title">Web Hosting Plan</h2>
            </div>
            <Container fluid>
                {/* item1 */}
                <Row className="feature-row ">
                    <Col lg={2} md={3} sm={3} xs={4} className="feature-box">
                        <FontAwesomeIcon
                            className="feature-icon"
                            icon={faExpeditedssl}
                        ></FontAwesomeIcon>
                        <h4 className="feature-box-title">
                            Free <br />
                            SSL
                        </h4>
                    </Col>

                    {/* item2 */}
                    <Col lg={2} md={3} sm={3} xs={4} className="feature-box">
                        <FontAwesomeIcon
                            className="feature-icon"
                            icon={faUser}
                        ></FontAwesomeIcon>
                        <h4 className="feature-box-title">
                            24/7 Technical <br />
                            Support
                        </h4>
                    </Col>
                    {/* item3 */}

                    <Col lg={2} md={3} sm={3} xs={4} className="feature-box">
                        <FontAwesomeIcon
                            className="feature-icon"
                            icon={faDesktop}
                        ></FontAwesomeIcon>
                        <h4 className="feature-box-title">
                            Free Webbly Site <br />
                            Builder
                        </h4>
                    </Col>
                    {/* item4 */}

                    <Col lg={2} md={3} sm={3} xs={4} className="feature-box">
                        <FontAwesomeIcon
                            className="feature-icon"
                            icon={faHardDrive}
                        ></FontAwesomeIcon>
                        <h4 className="feature-box-title">
                            SSD <br />
                            Technology
                        </h4>
                    </Col>
                    {/* item5 */}

                    <Col lg={2} md={3} sm={3} xs={4} className="feature-box">
                        <FontAwesomeIcon
                            className="feature-icon"
                            icon={faEnvelope}
                        ></FontAwesomeIcon>
                        <h4 className="feature-box-title">
                            Unlimited <br />
                            Professional Email
                        </h4>
                    </Col>
                    {/* item6 */}

                    <Col lg={2} md={3} sm={3} xs={4} className="feature-box">
                        <FontAwesomeIcon
                            className="feature-icon"
                            icon={faDatabase}
                        ></FontAwesomeIcon>
                        <h4 className="feature-box-title">
                            Free <br />
                            Cpanel
                        </h4>
                    </Col>
                </Row>
                <Row className="feature-rate">
                    <Col lg={5} md={6} sm={8}>
                        <img
                            src={featureRate}
                            alt="Google rate"
                            className="feature-rate-img"
                        />
                    </Col>
                </Row>
            </Container>
            <div className="feature-footer">
                <div className="feature-footer-wrap">
                    <h3 className="feature-footer-title">
                        Create your own website today!
                    </h3>
                    <div className="feature-footer-body">
                        <div className="feature-footer-content">
                            <p className="feature-footer-desc">
                                Starting at just
                            </p>
                            <p className="feature-footer-price">
                                $1.99
                                <span style={{ fontSize: "20px" }}>
                                    / mo <MoreInformation></MoreInformation>
                                </span>
                            </p>
                        </div>
                        <div className="feature-footer-btn">
                            <a
                                className="feature-footer-link"
                                href="https://secure.webhostingpad.com/billing/cart.php?gid=1"
                            >
                                Sign Up
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Include;
