import Card from "react-bootstrap/Card";
import "./MainContent.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCheck,
    faChevronDown,
    faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import MoreInformation from "../MoreInfor";
function MainContent() {
    const [isMorePL, setIsMorePL] = useState(false);
    const [isMorePLP, setIsMorePLP] = useState(false);

    const powerPlanFeature = [
        " Free Domain Name*",
        " Free SSL & SiteLock Lite",
        " Free Website Builder & WordPress",
        " Unlimited Email Accounts* ",
        " Host Unlimited Websites Domains*",
    ];
    const powerPlanFeatureFull = [
        " Free Domain Name*",
        " Free SSL & SiteLock Lite",
        " Free Website Builder & WordPress",
        " Unlimited Email Accounts* ",
        " Host Unlimited Websites Domains*",
        " Free Domain Name*",
        " Free SSL & SiteLock Lite",
        " Free Website Builder & WordPress",
        " Unlimited Email Accounts* ",
        " Host Unlimited Websites Domains*",
        " Free Domain Name*",
        " Free SSL & SiteLock Lite",
        " Free Website Builder & WordPress",
        " Unlimited Email Accounts* ",
        " Host Unlimited Websites Domains*",
    ];
    const powerPlanPlusFeature = [
        " Free Domain Name*",
        " Free Trusted SSL & SiteLock Lite",
        " Free 1-Click WordPress",
        " Optimized High-Power Servers",
        " Automated & Anytime Site Backup",
    ];
    const powerPlanPlusFeatureFull = [
        " Free Domain Name*",
        " Free Trusted SSL & SiteLock Lite",
        " Free 1-Click WordPress",
        " Optimized High-Power Servers",
        " Automated & Anytime Site Backup",
        " Free Domain Name*",
        " Free Trusted SSL & SiteLock Lite",
        " Free 1-Click WordPress",
        " Optimized High-Power Servers",
        " Automated & Anytime Site Backup",
        " Free Domain Name*",
        " Free Trusted SSL & SiteLock Lite",
        " Free 1-Click WordPress",
        " Optimized High-Power Servers",
        " Automated & Anytime Site Backup",
    ];
    const handleMorePL = () => {
        setIsMorePL(!isMorePL);
    };
    const handleMorePLP = () => {
        setIsMorePLP(!isMorePLP);
    };

    return (
        <Container fluid className="plan-wrap">
            <h2 className="plan-title">INCLUDED IN YOUR PLANS</h2>
            <p className="plan-desc">
                Find the right hosting plan for your website.
            </p>
            <Row className="plan-content">
                <Col xs={12} sm={8} md={6} lg={4} className="plan-card">
                    <Card>
                        <Card.Body>
                            <Card.Title>Power Plan</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">
                                All‑Inclusive Hosting
                            </Card.Subtitle>

                            <div className="card-features">
                                {isMorePL
                                    ? powerPlanFeatureFull.map(
                                          (feature, index) => (
                                              <p
                                                  key={index}
                                                  className="card-feature"
                                              >
                                                  <FontAwesomeIcon
                                                      style={{
                                                          color: "#5da244",
                                                      }}
                                                      icon={faCheck}
                                                  ></FontAwesomeIcon>
                                                  {feature}
                                              </p>
                                          )
                                      )
                                    : powerPlanFeature.map((feature, index) => (
                                          <p
                                              key={index}
                                              className="card-feature"
                                          >
                                              {" "}
                                              <FontAwesomeIcon
                                                  style={{
                                                      color: "#5da244",
                                                  }}
                                                  icon={faCheck}
                                              ></FontAwesomeIcon>
                                              {feature}
                                          </p>
                                      ))}
                            </div>
                            <div className="card-more" onClick={handleMorePL}>
                                {isMorePL ? (
                                    <p>
                                        {" "}
                                        show less{" "}
                                        <FontAwesomeIcon
                                            icon={faChevronUp}
                                        ></FontAwesomeIcon>
                                    </p>
                                ) : (
                                    <p>
                                        show all feature{" "}
                                        <FontAwesomeIcon
                                            icon={faChevronDown}
                                        ></FontAwesomeIcon>
                                    </p>
                                )}
                            </div>
                            <p className="card-sale">Starting at just</p>
                            <span className="card-price">
                                $1.99 / mo <MoreInformation></MoreInformation>
                            </span>
                            <div className="card-btn">
                                <a
                                    style={{
                                        display: "block",
                                        textAlign: "center",
                                    }}
                                    href="https://secure.webhostingpad.com/billing/cart.php?gid=1"
                                >
                                    Get Started!
                                </a>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} sm={8} md={6} lg={4} className="plan-card">
                    <Card>
                        <Card.Body>
                            <Card.Title>Power Plan Plus</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">
                                Optimized for WordPress
                            </Card.Subtitle>
                            <div className="card-features">
                                {isMorePLP
                                    ? powerPlanPlusFeatureFull.map(
                                          (feature, index) => (
                                              <p
                                                  key={index}
                                                  className="card-feature"
                                              >
                                                  {" "}
                                                  <FontAwesomeIcon
                                                      style={{
                                                          color: "#5da244",
                                                      }}
                                                      icon={faCheck}
                                                  ></FontAwesomeIcon>
                                                  {feature}
                                              </p>
                                          )
                                      )
                                    : powerPlanPlusFeature.map(
                                          (feature, index) => (
                                              <p
                                                  key={index}
                                                  className="card-feature"
                                              >
                                                  <FontAwesomeIcon
                                                      style={{
                                                          color: "#5da244",
                                                      }}
                                                      icon={faCheck}
                                                  ></FontAwesomeIcon>
                                                  {feature}
                                              </p>
                                          )
                                      )}
                            </div>
                            <div className="card-more" onClick={handleMorePLP}>
                                {isMorePLP ? (
                                    <p>
                                        {" "}
                                        show less{" "}
                                        <FontAwesomeIcon
                                            icon={faChevronUp}
                                        ></FontAwesomeIcon>
                                    </p>
                                ) : (
                                    <p>
                                        show all feature{" "}
                                        <FontAwesomeIcon
                                            icon={faChevronDown}
                                        ></FontAwesomeIcon>
                                    </p>
                                )}
                            </div>
                            <p className="card-sale">Starting at just</p>
                            <span className="card-price">
                                $2.99 / mo <MoreInformation></MoreInformation>
                            </span>

                            <div
                                className="card-btn"
                                style={{
                                    display: "block",
                                    textAlign: "center",
                                }}
                            >
                                <a href="https://secure.webhostingpad.com/billing/index.php?rp=/store/power-plan-plus-hosting">
                                    Get Started!
                                </a>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default MainContent;
