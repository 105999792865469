import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";
import "./moreinfo.scss";
//
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
//
import Table from "react-bootstrap/Table";
function MoreInformation() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <FontAwesomeIcon
                icon={faCircleQuestion}
                className="more-info"
                onClick={handleShow}
            ></FontAwesomeIcon>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Regular Rates</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    For full transparency, here are{" "}
                    <b>WebHostingPad's rates:</b>
                    {/* table1 */}
                    <h4 className="more-title">POWER PLAN</h4>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Billing Cycle</th>
                                <th>Intro Rate</th>
                                <th>Regular Rate</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1 year</td>
                                <td>$3.99 / mo</td>
                                <td>$6.49 / mo</td>
                            </tr>
                            <tr>
                                <td>2 years</td>
                                <td>$2.99 / mo</td>
                                <td>$5.99 / mo</td>
                            </tr>
                            <tr>
                                <td>3 years</td>
                                <td>$2.29 / mo</td>
                                <td>$5.49 / mo</td>
                            </tr>
                            <tr>
                                <td>4 years</td>
                                <td>$1.99 / mo</td>
                                <td>$4.99 / mo</td>
                            </tr>
                            <tr>
                                <td>5 years</td>
                                <td>$1.99 / mo</td>
                                <td>$4.99 / mo</td>
                            </tr>
                        </tbody>
                    </Table>
                    {/* table2 */}
                    <h4 className="more-title">
                        POWER PLAN PLUS + WORDPRESS BASIC
                    </h4>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Billing Cycle</th>
                                <th>Intro Rate</th>
                                <th>Regular Rate</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1 year</td>
                                <td>$4.99 / mo</td>
                                <td>$7.99 / mo</td>
                            </tr>
                            <tr>
                                <td>2 years</td>
                                <td>$3.99 / mo</td>
                                <td>$6.99 / mo</td>
                            </tr>
                            <tr>
                                <td>3 years</td>
                                <td>$2.99 / mo</td>
                                <td>$5.99 / mo</td>
                            </tr>
                        </tbody>
                    </Table>
                    {/* table3 */}
                    <h4 className="more-title">
                        POWER PLAN PLUS + WORDPRESS PRO
                    </h4>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Billing Cycle</th>
                                <th>Intro Rate</th>
                                <th>Regular Rate</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1 year</td>
                                <td>$5.99 / mo</td>
                                <td>$8.99 / mo</td>
                            </tr>
                            <tr>
                                <td>2 years</td>
                                <td>$4.99 / mo</td>
                                <td>$7.99 / mo</td>
                            </tr>
                            <tr>
                                <td>3 years</td>
                                <td>$3.99 / mo</td>
                                <td>$6.99 / mo</td>
                            </tr>
                        </tbody>
                    </Table>
                    {/* table4 */}
                    <h4 className="more-title">
                        POWER PLAN PLUS + WORDPRESS PREMIUM
                    </h4>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Billing Cycle</th>
                                <th>Intro Rate</th>
                                <th>Regular Rate</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1 year</td>
                                <td>$7.99 / mo</td>
                                <td>$9.99 / mo</td>
                            </tr>
                            <tr>
                                <td>2 years</td>
                                <td>$6.99 / mo</td>
                                <td>$8.99 / mo</td>
                            </tr>
                            <tr>
                                <td>3 years</td>
                                <td>$5.99 / mo</td>
                                <td>$7.99 / mo</td>
                            </tr>
                        </tbody>
                    </Table>
                    {/* table4 */}
                    <h4 className="more-title">POWER PLAN MINI</h4>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Billing Cycle</th>
                                <th>Regular Rate</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1 year</td>
                                <td>$3.50 / mo</td>
                            </tr>
                            <tr>
                                <td>2 years</td>
                                <td>$3.25 / mo</td>
                            </tr>
                            <tr>
                                <td>3 years</td>
                                <td>$3.00 / mo</td>
                            </tr>
                        </tbody>
                    </Table>
                    <p className="more-desc">
                        There is a <b>hosting industry standard</b> to offer
                        discounted prices to new sign-ups, and then revert back
                        to regular rates once the first term is up—much like
                        phone, television, and internet providers.
                    </p>
                    <p className="more-desc">
                        WebHostingPad also follows this standard, so the prices
                        you see listed are for new clients only. But while other
                        hosts will start charging $15.00/month for even their
                        cheapest plan after the first term, all of our shared
                        plans renew for less than $10.00/month,{" "}
                        <b>and our cheapest plan renews at only $4.49/month </b>
                        That makes our regular rates{" "}
                        <a
                            className="more-link"
                            href="https://www.webhostingpad.com/compare/"
                            target="blank"
                            style={{ color: "#5da244" }}
                        >
                            some of the most competitive in the industry.
                        </a>
                    </p>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default MoreInformation;
