import man from "../../assets/images/1.jpg";
import MoreInformation from "../MoreInfor";

function Intro() {
    return (
        <div className="intro-wrap">
            <img src={man} alt="man" className="intro-img" />
            {/* <div className="intro-space"></div> */}
            <div className="intro-body">
                <h2 className="intro-title">
                    Get a powerful Hosting and Domains
                </h2>
                <p className="intro-desc">
                    Features included with the power plan
                </p>
                <a
                    href="https://www.webhostingpad.com/ssl/"
                    className="intro-link"
                >
                    - SSL certificates
                </a>
                <a
                    href="https://www.webhostingpad.com/website-builder/"
                    className="intro-link"
                >
                    - Free website builder
                </a>
                <a
                    href="https://www.webhostingpad.com/wordpress/"
                    className="intro-link"
                >
                    - One click install WordPress
                </a>
                <a href="#!" className="intro-link">
                    - Free domain
                </a>
                <a href="#!" className="intro-link">
                    - 99.9% uptime
                </a>
                <a href="#!" className="intro-link">
                    - Technical support 24/7
                </a>

                <p className="intro-sale">sale off 60% for now! </p>
                <p className="intro-plan">
                    <span className="intro-price">$1.99 </span> / month{" "}
                    <MoreInformation></MoreInformation>
                </p>
                <div className="intro-btn">
                    <a href="https://secure.webhostingpad.com/billing/index.php?rp=/store/all-inclusive-hosting">
                        Start now!
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Intro;
